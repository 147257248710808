import React from "react"
import {Container} from 'reactstrap'
import {graphql} from "gatsby";

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import {LangContext} from "../components/kbusWrapper";
import ReactMarkdown from "react-markdown";


const PrivacidadPage = ({ data }) => (
  <LangContext.Consumer>
    { context => (
      <Layout>
        <SEO title={context.translate(data, 'privacidad', 'titulo')} />
        <Container className="py-5 mb-5">
          <PageTitle title={context.translate(data, 'privacidad', 'titulo')}/>
          <div className={"mt-4"}>
            <ReactMarkdown source={context.translate(data, 'privacidad', 'contenido')} className="text-muted"/>
          </div>
        </Container>
      </Layout>
    )}
  </LangContext.Consumer>
)

export default PrivacidadPage

export const pageQuery = graphql`  
  query {
    privacidad: strapiPrivacidad {
      titulo
      contenido
    }
    privacidad_eus: strapiPrivacidadEus {
      titulo
      contenido
    }
  }
`